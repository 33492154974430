<template>
  <div class="needs">
    <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
      <el-tab-pane
        :label="item.name"
        :name="'status' + item.status"
        v-for="(item, i) in status"
        :key="i"
      >
        <div class="item" v-for="(item, i) in list" :key="i">
          <div class="title">
            {{ item.product_name }}
            <span class="status" :style="{ color: item.status_text.color }">{{
              item.status_text.name
            }}</span>
          </div>
          <div class="intro">
            <div>发布日期：{{ item.rtime_text }}</div>
            <div>需求单位：{{ item.company_name }}</div>
            <div>需求地址：{{ item.company_address }}</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeName: "statusall",
      status: [
        {
          name: "全部",
          status: "all",
        },
        {
          name: "待确认",
          status: 1,
        },
        {
          name: "服务中",
          status: 2,
        },
        {
          name: "待结算",
          status: 3,
        },
        {
          name: "服务完成",
          status: 4,
        },
      ],
      list: [
        //    {
        //    company_address: "1"
        //   company_name: "最后一次"
        //   id: 12
        //   product_name: "工业用气节能，废水回收"
        //   rtime_text: "2022-06-11 11:19"
        //  }
      ],
      currentPage: 0,
      total: 0,
      statusindex: 0,
    };
  },

  computed: {},
  mounted() {
      window.scroll({ top: 0, left: 0});
    this.getdata();
  },
  methods: {
    handleClick(tab, event) {
     this.statusindex=this.status.findIndex(obj=>this.activeName.indexOf(obj.status)>0)
     this.getdata();
    },
    getdata() {
      this.$http
        .demandlist({
          status: this.status[this.statusindex].status,
          offset: this.currentPage * 999,
          limit: 999,
        })
        .then((res) => {
          this.list = res.data;
          // console.log(this.list)
        });
    },
  },
};
</script>
<style scoped lang="scss">
.needs {
  padding: 30px;
}
/deep/.el-tabs__item {
  font-size: 20px;
  &.is-active {
    font-weight: bold;
  }
}
.item {
  border: 1px solid #999;
  border-radius: 5px;
  margin-top: 20px;
  .title {
    color: #666;
    font-weight: bold;
    font-size: 20px;
    padding: 20px;
    border-bottom: 1px solid rgba($color: #999, $alpha: 0.2);
    .status {
      float: right;
      font-size: 18px;
      font-weight: normal;
      &.status1 {
        color: #18f590;
      }
      &.status2 {
        color: #1879f5;
      }
      &.status3 {
        color: #f59018;
      }
      &.status4 {
        color: #f51818;
      }
    }
  }
  .intro {
    font-size: 18px;
    color: #666;
    line-height: 2;
    padding: 20px;
  }
}
</style>
